import { template as template_6226e7a02a8043cebbf34650ecd8cf49 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6226e7a02a8043cebbf34650ecd8cf49(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
