import { template as template_81cab7aaf1e244f4a0bcdce2c14471e7 } from "@ember/template-compiler";
const FKLabel = template_81cab7aaf1e244f4a0bcdce2c14471e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
